
.Sidebar {
    height: 100%;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #323C3E;
    display: flex;
    flex-direction: column;
}

.Sidebar button:hover {
    filter:brightness(1.5);
}

.Sidebar button {
    color: white;
    background: #323C3E;
    border: none;
    min-width: 100%;
    padding-left: 15px;
    text-align: left;
    font-size: large;
}

.SidebarInnstillinger {
    position: relative;
    bottom: -70%;
}

.DashboardHeader {
    font-size: xx-large;
    padding: 5%;
}

.DashboardHeader button:hover {
    filter:brightness(1.5);
}