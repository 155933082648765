.Users {
  align-items: flex-start;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 10%;
}

.UserInfo {
  margin-left: 4%;
  flex-direction: column;
  margin-top: 2%;
}
.TableImage {
  flex-direction: row;
}

.avatarImage {
  margin-top: 3%;
  margin-left: 0%;
  margin-right: 3%;
  height: 16%;
  width: 16%
}

.breakTable {
  flex-basis: 100%;
  height: 20%;
}

.SearchGlobalFilter {
  position: absolute;
  width: 300;
  margin-top: 2%;
}

.headerUser {
  margin-bottom: 2%;
}

.chart {
  margin-top: 4%;
}

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 400;
   left: -50;
   margin-right: 10;
   margin-bottom: 4%;
   margin-top: 2%;
  }
  
table td, table th {
    border: 1px solid #ddd;
    padding: 2px;
    width: 5px;
  }
  
table tr:nth-child(even){
  background-color: #f2f2f2;}
  
table tr:hover {background-color: #ddd;}
  
table th, tfoot td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #5F6F72;
    color: white;
  }