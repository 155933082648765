.Main {
    margin-left: 23%;
    margin-right: 3%;
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    width: 100%;
    margin-bottom: 0;
  }

  .WeekYear {
      font-size: small;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
  
  .SearchGlobalFilter {
    position: relative;
    margin-left: auto;
    align-self:flex-end
  }
  
  table {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      align-self: flex-end;
    }
    
  table td, table th {
      border: 1px solid #ddd;
      padding: 8px;
    }
    
  table tr:nth-child(even){background-color: #f2f2f2;}
    
  table tr:hover {background-color: #ddd;}
    
  table th, tfoot td {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: #5F6F72;
      color: white;
    }